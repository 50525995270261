<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferees</a>
                    <div class="step">
                      <a class="step-link" href="javascript:void(0)">Transferee 1</a>
                    </div>
                    <div class="step">
                      <a class="step-link" href="javascript:void(0)">Transferee 2</a>
                    </div>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Terms</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step active">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">First Time Home Buyers</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Certification</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-8">
              <div class="alert alert-info">
                <div class="alert-icon">
                  <svg class="icon icon-info"><use xlink:href="/icons/symbol-defs.svg#icon-info"></use></svg>
                </div>
                {Information describing process changes and the following information is now needed for all filing}.
              </div>

              <h2>Property Value Info</h2>
              <p class="lead">After entering dollar amounts, click Calculate and Save to ensure accurate calculations for your return.</p>

              <div class="mb-4 bg-medium">
                <div class="p-3">
                  <div class="row align-items-center">
                    <label class="col-md-6" for="propertySize1">Total Size of Property</label>
                    <div class="d-flex col-md-6">
                      <input type="text" class="form-control mr-1 text-right" id="propertySize1">
                      <select class="form-control" id="units1">
                        <option>Hectares</option>
                        <option>Acres</option>
                        <option>Square Feet</option>
                      </select>
                    </div>
                  </div>

                  <div class="row align-items-center mt-2">
                    <label class="col-md-6" for="propertySize1">Total Size of Farm Portion of Property</label>
                    <div class="d-flex col-md-6">
                      <input type="text" class="form-control mr-1 text-right" id="propertySize1">
                      <select class="form-control" id="units1">
                        <option>Hectares</option>
                        <option>Acres</option>
                        <option>Square Feet</option>
                      </select>
                    </div>
                  </div>

                  <label class="mt-4">Is there a dwelling on the farm portion of the property?</label>
                  <div class="form-group mt-1 mb-0">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="dwellingOnProperty" name="dwellingOnProperty" class="custom-control-input">
                      <label class="custom-control-label" for="dwellingOnProperty">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="dwellingOnProperty2" name="dwellingOnProperty" class="custom-control-input">
                      <label class="custom-control-label" for="dwellingOnProperty2">No</label>
                    </div>
                  </div>
                </div>


                <div class="bg-medium-dark py-1 px-3">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-9">
                      <label for="allImprovementsProperty"><strong>Total Value of All Improvements on the Property</strong></label>
                    </div>
                    <div class="col-3">
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                        <input type="text" class="form-control text-right border-left-0" id="allImprovementsProperty">
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row form-group mb-0 py-2">
                  <div class="col-6">
                    <label class="pl-3" for="residentialImprovementsOnly"><small>Value of Residential Improvements Only</small></label>
                  </div>
                  <div class="col-3">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                      <input type="text" class="form-control form-control-sm text-right border-left-0" id="residentialImprovementsOnly">
                    </div>
                  </div>
                </div>

                <div class="bg-medium-dark py-1 px-3">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-9">
                      <label for="allLandValue"><strong>Total Value of All Land on the Property</strong></label>
                    </div>
                    <div class="col-3">
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text">$</span></div
                        ><input type="text" class="form-control text-right border-left-0" id="allLandValue">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row form-group mb-0 py-2">
                  <div class="col-6">
                    <label class="pl-3" for="residentialLandOnly"><small>Value of Residential Land Only</small></label>
                  </div>
                  <div class="col-3">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                      <input type="text" class="form-control form-control-sm text-right border-left-0" id="residentialLandOnly">
                    </div>
                  </div>
                </div>

                <div class="bg-medium-dark py-3 px-3">
                  <div class="row justify-content-between align-items-center">
                    <h4 class="col-8 mb-0 pl-2">
                      Total Value of Property
                      <a href="#" data-toggle="popover" data-placement="top" data-content="Help Text" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </h4>
                    <h4 class="col-4 mb-0 text-right">{$########}</h4>
                  </div>
                </div>

                <div class="p-3">
                  <div class="row justify-content-between align-items-center mb-4">
                    <div class="col-9">
                      <label for="allImprovementsProperty">
                        <strong>Full Fair Market Value of Property </strong>
                        <a href="#" data-toggle="popover" data-placement="top" data-content="Enter 100% of the total fair market value no matter what percentage interest in this property is being transferred. This form will automatically apply the actual percentage interest transferred (as completed in the Property Description section), before tax is calculated.  See the General Guide for for more information." v-popover>
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        </a>
                        <br>
                        (if different from the Total Value of Property)
                      </label>
                    </div>
                    <div class="col-3">
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                        <input type="text" class="form-control text-right border-left-0" id="allImprovementsProperty">
                      </div>
                    </div>
                  </div>

                  <h3>Eligibility for Exemption</h3>

                  <div class="row justify-content-between align-items-center mt-2">
                    <div class="col-8">
                      Land Value Portion Eligible for Exemption
                    </div>
                    <div class="col-4 text-right">{$########}</div>
                  </div>

                  <div class="row justify-content-between align-items-center mt-2">
                    <div class="col-8">
                      Value of residential Improvements and Land Eligible for Exemption
                    </div>
                    <div class="col-4 text-right">{$########}</div>
                  </div>

                  <a class="collapse-toggle d-block mt-2" href="#elegibilityHelp" data-toggle="collapse" aria-expanded="false" aria-controls="elegibilityHelp">
                    <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                    <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                    How is eligibility for exemption calculated?
                  </a>
                  <div class="collapse" id="elegibilityHelp">
                    <div class="card bg-white mt-1">
                      <div class="card-body">
                        Help content
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

